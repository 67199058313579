<template>
  <div class="academic-years">
    <lenon-list-page
      title="Terminal Assessments"
      :columns="columns"
      :rows="assessments"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-profile-photo="true"
      sort-column="total_score"
      sort-dir="DESC"
      search-placeholder="Search"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -20px;"
        >
          <lenon-select
            v-model="selectedAcademicYearId"
            placeholder="Academic Year"
            :options="academicYears"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedTermId"
            placeholder="Select Term"
            :options="filteredTerms"
            label-name="name"
            value-name="ay_term_id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedClassId"
            placeholder="Select Class"
            :options="classes"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedSubjectId"
            style="width: 150px !important;"
            placeholder="Select Subject"
            :options="subjects"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-button
            v-if="isAdmin"
            icon="XIcon"
            label="Clear"
            variant="outline-danger"
            class="mr-1"
            tool-tip-text="Clear recorded scores for subject"
            @onClick="clearScores()"
          />
          <lenon-button
            icon="ListIcon"
            label="Sheet"
            :loading="generating"
            tool-tip-text="Generate Assessment Sheet"
            @onClick="generateSheet()"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-button
            v-b-toggle.assessment
            icon-only
            icon="MoreVerticalIcon"
            variant="flat-primary"
          />
        </div>
      </template>
      <template slot="table-header">
        <div class="col-12">
          <b-collapse
            id="assessment"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedAcademicYearId"
                  placeholder="Academic Year"
                  :options="academicYears"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedTermId"
                  placeholder="Select Term"
                  :options="filteredTerms"
                  label-name="name"
                  value-name="ay_term_id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedClassId"
                  placeholder="Select Class"
                  :options="classes"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedSubjectId"
                  style="width: 150px !important;"
                  placeholder="Select Subject"
                  :options="subjects"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div
                v-if="isAdmin"
                class="col-md-6 float-right mb-1"
              >
                <lenon-button
                  icon="XIcon"
                  label="Clear Scores"
                  variant="outline-danger"
                  tool-tip-text="Clear recorded scores for subject"
                  @onClick="clearScores()"
                />
              </div>
              <div class="col-md-6 float-right mb-1">
                <lenon-button
                  icon="ListIcon"
                  label="Generate Sheet"
                  variant="outline-primary"
                  :loading="generating"
                  tool-tip-text="Generate Assessment Sheet"
                  @onClick="generateSheet()"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
    </lenon-list-page>
  </div>
</template>

<script>
import { BCollapse, VBToggle } from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { FETCH_TERMINAL_ASSESSMENTS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import confirm from '@/lenon/mixins/confirm'
import { GENERATE_ASSESSMENT_SHEET_M } from '@/graphql/mutations'

export default {
  name: 'FeePayments',
  components: {
    LenonSelect,
    LenonListPage,
    LenonButton,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      tableLoading: false,
      selectedSubjectId: null,
      selectedAcademicYearId: null,
      selectedTermId: null,
      selectedClassId: null,
      generating: false,
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters['auth/role'] === 'Administrator'
    },
    monitorSettings() {
      return +this.selectedTermId
          * +this.selectedClassId
          * +this.selectedSubjectId
    },
    previousSetting() {
      return this.$store.getters['assessments/previousSettings']
    },
    assessments() {
      return this.$store.getters['assessments/assessments']
    },
    columns() {
      return this.$store.getters['assessments/assessmentColumns']
    },
    classes() {
      if (!this.selectedTerm) {
        return []
      }
      const classes = this.$store.getters['termsClasses/classes']
      if (this.selectedTerm.tag_id) {
        return classes.filter(c => this.selectedTerm.classes.includes(c.id))
      }
      return classes
    },
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
    },
    selectedTerm() {
      return this.terms.find(term => term.ay_term_id === this.selectedTermId)
    },
  },
  watch: {
    monitorSettings(valid) {
      if (valid) {
        this.fetchAssessments()
        this.$store.commit('assessments/setSettings', {
          selectedSubjectId: this.selectedSubjectId,
          selectedAcademicYearId: this.selectedAcademicYearId,
          selectedTermId: this.selectedTermId,
          selectedClassId: this.selectedClassId,
        })
      } else {
        this.$store.commit('assessments/setTerminalAssessments', {
          terminalAssessments: {
            columns: [],
            data: [],
          },
        })
      }
    },
  },
  mounted() {
    this.setSettings()
    this.$store.commit('assessments/setTerminalAssessments', {
      terminalAssessments: {
        columns: [],
        data: [],
      },
    })
  },
  methods: {
    generateSheet() {
      if (!this.assessments.length) {
        this.showInfo('There are no recorded assessments')
      } else if (this.monitorSettings) {
        this.generating = true
        this.$apollo.mutate({
          mutation: GENERATE_ASSESSMENT_SHEET_M,
          variables: {
            input: {
              academic_year_id: this.selectedAcademicYearId,
              term_id: this.selectedTerm.id,
              class_id: this.selectedClassId,
              subject_id: this.selectedSubjectId,
            },
          },
        })
          .then(res => {
            this.generating = false
            if (res.data.generateAssessmentSheet) {
              this.downloadFile(res.data.generateAssessmentSheet, 'Assessments_Sheet.pdf')
            }
          })
          .catch(err => {
            this.generating = false
            this.showError('Something went wrong, please try again')
          })
      } else {
        this.showInfo('Please make sure the correct options are selected')
      }
    },
    clearScores() {
      if (this.selectedSubjectId && this.selectedClassId) {
        this.confirmAction('Clearing scores for this subject will only clear the recorded total scores, which hides the subject from report cards for this class. Note that scores will be cleared for current term regardless of the academic year or term you have selected.', 'Ok, Proceed').then(res => {
          if (res) {
            this.tableLoading = true
            this.$http.delete(`academics/delete-exam-score-for-subject/${this.selectedSubjectId}?class_id=${this.selectedClassId}`).then(res => {
              this.showSuccess('Cleared successfully')
              this.fetchAssessments()
            }).catch(err => {
              this.showError('Failed to clear scores for this subject')
            }).finally(() => {
              this.tableLoading = false
            })
          }
        })
      } else {
        this.showInfo('Please select subject and class')
      }
    },
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = '_blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    setSettings() {
      this.selectedAcademicYearId = this.previousSetting?.selectedAcademicYearId
      this.selectedTermId = this.previousSetting?.selectedTermId
      this.selectedClassId = this.previousSetting?.selectedClassId
      this.selectedSubjectId = this.previousSetting?.selectedSubjectId
    },
    fetchAssessments() {
      this.tableLoading = true
      this.$apollo.query({
        query: FETCH_TERMINAL_ASSESSMENTS_Q,
        variables: {
          input: {
            academic_year_id: this.selectedAcademicYearId,
            term_id: this.selectedTerm.id,
            class_id: this.selectedClassId,
            subject_id: this.selectedSubjectId,
          },
        },
      })
        .then(res => {
          this.tableLoading = false
          if (res.data) {
            this.$store.commit('assessments/setTerminalAssessments', res.data)
          }
        })
        .catch(err => {
          this.tableLoading = false
          this.showError('Something went wrong, please try again')
        })
    },
  },
}
</script>
